var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper navbar-full p-0" },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "navbar-custom navbar-skelton",
            class: _vm.navbarClasses,
            style: _vm.navbarStyle,
            attrs: { color: _vm.navbarColor },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "vx-logo cursor-pointer mr-auto flex",
                attrs: { tag: "div", to: "/" },
              },
              [
                _c("img", {
                  staticClass: "w-1/2 fill-current text-primary",
                  attrs: {
                    src: require("@/assets/images/logo/logo.svg"),
                    alt: "LatinAd",
                  },
                }),
              ]
            ),
            _c("profile-drop-down"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }