var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeUserInfo.first_name
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "div",
            { staticClass: "text-right leading-tight hidden sm:block" },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(
                  _vm._s(_vm.activeUserInfo.first_name) +
                    " " +
                    _vm._s(_vm.activeUserInfo.last_name)
                ),
              ]),
              _c("small", [_vm._v(_vm._s(_vm.activeUserRole))]),
            ]
          ),
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _c("div", { staticClass: "con-img ml-3" }, [
                _vm.activeUserInfo.avatar
                  ? _c("img", {
                      key: "onlineImg",
                      staticClass:
                        "rounded-full shadow-md cursor-pointer block",
                      attrs: {
                        src: _vm.activeUserInfo.avatar,
                        alt: "user",
                        width: "40",
                        height: "40",
                      },
                    })
                  : _vm._e(),
                !_vm.activeUserInfo.avatar
                  ? _c("img", {
                      key: "onlineImg",
                      staticClass:
                        "rounded-full shadow-md cursor-pointer block",
                      attrs: {
                        src: `https://ui-avatars.com/api/?background=2196F3&color=FFFFFF&size=200&bold=true&name=${_vm.activeUserInfo.first_name}+${_vm.activeUserInfo.last_name}`,
                        alt: "user",
                        width: "40",
                        height: "40",
                      },
                    })
                  : _vm._e(),
              ]),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                  _c(
                    "li",
                    {
                      staticClass:
                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                      on: { click: _vm.logout },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" },
                      }),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("Logout")]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }