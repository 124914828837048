/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: '/',
    name: 'Campañas',
    slug: 'campaigns',
    icon: 'BookIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/clients',
    name: 'Clientes',
    slug: 'clients',
    icon: 'UsersIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/billing',
    name: 'Facturación',
    slug: 'billing',
    icon: 'ClipboardIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/charges',
    name: 'Cobros',
    slug: 'charges',
    icon: 'DollarSignIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/payments',
    name: 'Pagos',
    slug: 'payments',
    icon: 'CreditCardIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/closings',
    name: 'Cierres',
    slug: 'closings',
    icon: 'InboxIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/stats',
    name: 'Estadísticas',
    slug: 'stats',
    icon: 'ActivityIcon',
    checkPermission: 'show_statistic_kpi'
  },
  {
    url: '/screens',
    name: 'Pantallas',
    slug: 'screens',
    icon: 'MonitorIcon',
    checkPermission: 'list_panel_statistic_campaigns'
  },
  {
    url: '/licenses',
    name: 'Licencias',
    slug: 'licenses',
    icon: 'StarIcon',
    checkPermission: 'show_licenses'
  },
  {
    url: '/contable',
    name: 'Contable y BI',
    slug: 'contable',
    icon: 'BarChart2Icon',
    checkPermission: 'show_licenses'
  },
  {
    url: '/goals',
    name: 'Objetivos',
    slug: 'goals',
    icon: 'CrosshairIcon',
    checkPermission: 'show_licenses'
  }
  // {
  //   url: '/page2',
  //   name: 'Page 2',
  //   slug: 'page2',
  //   icon: 'FileIcon'
  // }
]
